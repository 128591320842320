<template>
  <div></div>
</template>

<script>
export default {
  name: "Signup",

  mounted() {
    this.$router.push("/signup/monthly");
  },
};
</script>

<style></style>
